import React,{ Component } from 'react'
import { connect } from 'react-redux';

class Slider extends Component {
    /*constructor(props){
        super(props);
        
        this.state = {
            newTarget: 0,
            oldTarget: 0
        }
        
    }
    loop3;
    componentDidMount(){
        
        if(this.props.id == 0){
            this.loop3 = setInterval(this.loop2, 4000);
        }
    }
    componentDidUpdate(prevProp){
        // console.log("main",this.props.main);
        // console.log("id",this.props.id);
        // if(this.props.main == this.props.id){
        //     console.log("girdi");
        //     setInterval(this.loop2, 4000);
        // }
        if(this.props.main !== prevProp.main){
            clearInterval(this.loop3);
            setInterval(this.loop2, 4000);
        }
    }
    componentWillUnmount(){
        clearInterval(this.loop2);
    }
    loop2 = async() => {
            if(this.state.newTarget === document.getElementsByClassName(`img_slider-${this.props.main}`).length - 1){
                this.setState({oldTarget: this.state.newTarget, newTarget: 0}, () => {
                    this.getImage();
                    this.auto_button();
                })
            }else{
                await this.setState({oldTarget: this.state.newTarget, newTarget: this.state.newTarget + 1}, () => {
                    this.getImage();
                    this.auto_button();
                })
            }
        }
    bottom_button = async(e) => {
        if(e.target.id !== "bottom_button_main"){
            const value = e.target.className.split(" ")[1];
            if(value !== "active"){
                const old_Target = document.getElementsByClassName("but active")[0];
                old_Target.className = "but passive ml-2 w-3 h-3 rounded-full";
                const target = document.getElementById(e.target.id);
                target.className = "but active ml-2 w-3 h-3 rounded-full";
                await this.setState({newTarget: parseInt(e.target.id), oldTarget: old_Target.id});
                this.getImage()
            }
        }
    }
    auto_button = async() => {
        const value = document.getElementById(this.state.newTarget);
        if(value.className.split(" ")[1] !== "but active"){
            const old_Target = document.getElementsByClassName("but active")[0];
            old_Target.className = "but passive ml-2 w-3 h-3 rounded-full";
            value.className = "but active ml-2 w-3 h-3 rounded-full";
        }
    }
    getImage = () => {
        const new_img = document.getElementsByClassName(`img_slider-${this.props.main}`)[this.state.newTarget];
        const old_img = document.getElementsByClassName(`img_slider-${this.props.main}`)[this.state.oldTarget];
        let a = 1;
        let b = 0;
        const loop = setInterval(() => {
            new_img.style.opacity = b;
            old_img.style.opacity = a;
            b = b + 0.1;
            a = a - 0.1;
            if(b >= 1){
                clearInterval(loop);
            }
        },30)
    }
    */
    render() {
        let classname = `slider slider-${this.props.id} h-full w-full relative`
        let img_class = `img_slider-${this.props.id} h-full w-full absolute`
        return (
            <div className={classname}>
                <div className='w-full h-full bg-cover bg-center bg-no-repeat'>
                    {
                        this.props.img.map((item, i) => {
                            return(
                                <>
                                    {i === 0 ?
                                        <img className={img_class} style={{opacity: 1}} src={require(`../project_img/${item}`)} alt=""  />
                                        :
                                        <img className={img_class} style={{opacity: 0}} src={require(`../project_img/${item}`)} alt=""  />
                                    }
                                </>
                            )
                        })
                    }
                    {/* <img className={img_class} style={{opacity: 1}} src="https://i1.imgiz.com/listeler/MY57UMVEUFEG827W7DFPMC564BMD3K8F_1481720543.jpeg" alt=""  />
                    <img className={img_class} style={{opacity: 0}} src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" alt=""  />
                    <img className={img_class} style={{opacity: 0}} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXVL5BFE8D8kOW52bcbhprS66VvOBOn15EPaG39e0THyGZ09M78M6z5YKZ9Xs-5kOY4_Q&usqp=CAU" alt=""  /> */}
                </div>
                {/* <div className='absolute bottom-1 w-full flex justify-center items-center h-10'>
                    <div id='bottom_button_main' className='flex justify-between' onClick={(e) => this.bottom_button(e)}>
                        <button type="button" id='0' className="but active ml-2 w-3 h-3 rounded-full"></button>
                        <button type="button" id='1' className="but passive ml-2 w-3 h-3 rounded-full"></button>
                        <button type="button" id='2' className="but passive ml-2 w-3 h-3 rounded-full"></button>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return{
        main: state.site.main
    }
}

export default connect(mapStateToProps)(Slider);