import React, { useState } from 'react'
import { Route, Routes, useLocation } from "react-router-dom";
import Home from './Home';
import Projects from './Projects';
import Cv from "./Cv";

import { AnimatePresence } from "framer-motion";

const AnimatedRoutes = () => {
    const location = useLocation();
    const [open, setOpen] = useState(0);
    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<Home open={open}/>}/>
                <Route path='projects' element={<Projects setOpen={setOpen}/>} />
                <Route path='cv' element={<Cv setOpen={setOpen}/>} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes