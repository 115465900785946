/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect} from 'react'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCode } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import cv  from "../img/cv.png";
import { useDispatch } from 'react-redux';
//import { getData } from './../service/service';
import { setProje} from "../store/site"


const Home = ({open}) => {
  const dispatch = useDispatch();
    useEffect(()=> {
      const fun = async() => {
        /*
        if(open === 0){
          await getData();
        }
        */
        if(open === 0){
          await dispatch(setProje());
        }
        const load = document.getElementById("loading");
        const buttons = document.getElementById("buttons");
        const name_col = document.getElementById("name_col")
          if(open === 1){
            load.style.width = `${window.innerWidth / 2.5}px`;
            buttons.style.top = `51%`
            name_col.style.opacity = 1
          }else{
            let i = 1;
            let max_load = window.innerWidth / 2.5;
            if(window.innerWidth < 500){
                max_load = window.innerWidth / 1.5;
            }
            const loop = setInterval(()=> {
                load.style.width = `${i}px`;
                i += 1;
                if(i > max_load){
                    clearInterval(loop);
                    name_col.style.opacity = 1
                    i = 45;
                        const loop_3 = setInterval(()=> {
                            buttons.style.top = `${i}%`;
                            i += 1;
                            if(i > 51){
                                clearInterval(loop_3);
                            }
                    },25)
                }
            },0.001)
          }
      }
      fun();
    },[])
    
    return (
        <motion.div 
          id="mainDiv" 
          className="bg-stone-900 h-full w-full flex justify-center items-center flex-col overflow-hidden"

          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0, transition: {duration: 0.1}}}
          >

          <div id="loading" className="h-[3px] w-[1px] bg-white rounded-extraLarge absolute" style={{top: "50%"}}></div>
        
          <div className=" absolute bottom-4 cursor-pointer">
            <div className=" flex">
              <div className="relative">
              <Link to={"/cv"} target="_blank">
                  <a className="button" target="_blank" >
                    <div className="icon">
                        <img src={cv} className=" w-9 h-9 p-2" alt=''/>
                      </div>
                      <div className="btn_text right-[calc(50%_-_10px)]">
                        <p>CV</p>
                    </div>
                  </a>
                </Link>
              </div>
              <div className="relative ml-5">
                <Link to={"projects"}>
                  <a target="_blank" rel="noreferrer" className="button">
                    <div className="icon">
                      <FontAwesomeIcon className="icon_item" icon={faCode} />
                    </div>
                    <div className="btn_text right-[calc(50%_-_35px)]">
                      <p>Projects</p>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
        </div>
        

        <div id="buttons" className=" absolute top-[calc(50%_-_50px)] right-[calc(%50_-_106px)] flex-col">
          <div className="flex">
            <div className="relative">
              <a href="https://www.instagram.com/ozlerrhalil/" target="_blank" rel="noreferrer" className="button" >
                <div className="icon">
                  <FontAwesomeIcon className="icon_item" icon={faInstagram} />
                </div>
                <div className="btn_text right-[calc(50%_-_39px)]">
                  <p>Instagram</p>
                </div>
              </a>
            </div>
            <div className="relative ml-5">
              <a href="https://github.com/Halilozler" target="_blank" rel="noreferrer" className="button">
                <div className="icon">
                  <FontAwesomeIcon className="icon_item" icon={faGithub} />
                </div>
                <div className="btn_text right-[calc(50%_-_24px)]">
                  <p>Github</p>
                </div>
              </a>
            </div>
            <div className="relative ml-5">
              <a href="mailto: halil_ozler@hotmail.com" target="_blank" rel="noreferrer" className="button">
                <div className="icon">
                  <FontAwesomeIcon className="icon_item" icon={faEnvelope} />
                </div>
                <div className="btn_text right-[calc(50%_-_20px)]">
                  <p>Email</p>
                </div>
              </a>
            </div>
            <div className="relative ml-5">
              <a href="https://www.linkedin.com/in/halilozler/" target="_blank" rel="noreferrer" className="button">
                <div className="icon">
                  <FontAwesomeIcon className="icon_item" icon={faLinkedinIn} />
                </div>
                <div className="btn_text right-[calc(50%_-_36px)]">
                  <p>LinkedIn</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className=" absolute top-0 h-[49.8%] w-[100vw] bg-stone-900">
          <div className=" absolute top-5 right-5">
            
          </div>
          <div id="name_col" className=" text-white absolute bottom-2 tel:right-[calc(50%_-_210px)] right-[calc(50%_-_126px)] opacity-0 transition-opacity ease-linear">
            <p className=" text-7xl font-font">
              I'm
            </p>
            <h1 className="text-4xl font-font bg-stone-600 tel:text-6xl">
              Halil <span>Ozler</span>
            </h1>
          </div>
        </div>
      </motion.div>
    )
}

export default Home