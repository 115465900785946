import React from 'react'
import Slider from "./Slider";
import Tools from './Tools';

const ProjeItem = ({item, id}) => {
    return (
        <>
            <div className=" bg-gray-900 h-full w-[calc(100%_-_384px)]">
                <div className="relative h-16">
                    <div className="absolute top-4 left-4 h-[50px] flex justify-between">
                        {
                            item.top_Link.map(key => {
                                return(
                                    <Tools item={key} location={true}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="my-5 h-[calc(100%_-_100px)] flex justify-end">
                    <div className="h-full w-[calc(100%_-_100px)] mr-5 flex flex-col">
                        <div className="w-full h-[calc(100%_-_50px)] overflow-auto">
                            <div className="pb-7 w-full flex items-center justify-center text-3xl">
                                {item.title}
                            </div>
                            <div className="text-xl">
                                {item.text}
                            </div>
                        </div>
                        <div className="w-full h-[50px] flex justify-center">
                            {
                                item.bottom_Link.map(key => {

                                    return(
                                        <Tools item={key} location={false}/>
                                    )
                                })
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-full w-96'>
                <Slider id={id} img={item.img_Url}/>
            </div>
        </>
    )
}

export default ProjeItem