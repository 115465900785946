import React, {useEffect, lazy} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import net from "../img/net.png";
import site from "../img/site.png";
import sql from "../img/sql.png";
import { useState } from 'react';


const Tools = ({item, location}) => {
    const [font, setFont] = useState(false);
    const [icon, setIcon] = useState("");
    useEffect(()=> {
        if(item?.img_name?.slice(0,2) === "fa"){
            setFont(true);
            let v_icon = require("@fortawesome/free-brands-svg-icons").fab[item.img_name];
            if(v_icon === undefined){
                v_icon = require("@fortawesome/free-solid-svg-icons").fas[item.img_name];
            }
            setIcon(v_icon);
        }else if(item?.img_name){
            setIcon(require(`../img/${item.img_name}.png`))
        }
    },[])
    return (
        <>
        {location === true ?
            <div className='slider_item'>
                {font === true ?
                <FontAwesomeIcon className="icon_item_2  brightness-0 invert" icon={icon}/>
                :
                <img src={icon} className="icon_project  brightness-0 invert"/>
                }
                <div className='slider_arrow'></div>
                <div className='slider_link'>
                    {item?.link}
                </div>
            </div>
        :
            <div className='slider_item'>
                <div className='slider_arrow lg:bottom-[50px] bottom-[10px] left-[2px] border-t-black border-t-8 border-x-transparent border-x-8 border-b-0'></div>
                <div className='slider_link lg:bottom-[55px] bottom-[15px]'>
                    {item?.pdf ?
                        "open pdf"
                    :
                        item?.link
                    }
                    
                </div>
                {font === true ?
                    item.pdf === true ?
                    <a href={require(`../pdf/project reports/${item.link}.pdf`)} target="_blank">
                        <FontAwesomeIcon className="icon_item_2 brightness-0 invert" icon={icon}/>
                    </a>
                    :
                    <a href={item.link} target="_blank">
                        <FontAwesomeIcon className="icon_item_2 brightness-0 invert" icon={icon}/>
                    </a>
                :
                    item.pdf === true ?
                    <a href={require(`../pdf/project reports/${item.link}.pdf`)} target="_blank">
                        <img src={icon} className="icon_project  brightness-0 invert"/>
                    </a>
                    :
                    <a href={item.link} target="_blank">
                        <img src={icon} className="icon_project  brightness-0 invert"/>
                    </a>
                }
            </div>
        }
        </>
    )
}

export default Tools