export const data = 
{
        0: {
            title: "Otomatik Yoklama Sistemi",
            text: "Bitirme Projesi kapsamında ders saati sırasında öğrenci yüzlerini tanıyıp yoklama sistemine yazımı sağlanmıştır. Bunun yanında internet sitesi kurularak canlı olarak takip sağlanmıştır.",
            img_Url: ["one_one.png", "one_two.png", "one_three.png", "one_four.png", "one_five.png", "one_six.png", "one_seven.png"],
            top_Link: [
                {img_name: 'sql', link: 'MSSQL'},
                {img_name: 'net', link: 'Asp.Net Core Web Api'},
                {img_name: 'faAngular', link: 'Angular'},
                {img_name: 'faPython', link: 'Python'},
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/Bitirme-Projesi'},
                {img_name: 'faFile', link: "auto attendance system", pdf: true},
            ]
        },
        1: {
            title: "Animalous",
            text: "Bir hayvan severler sosyal ağ platformu olan Animalous. React Native kullanılarak hayvanlar hakkında yardımlaşma platformu yapılmıştır.",
            img_Url: ["two_one.png", "two_two.png", "two_three.png", "two_four.png", "two_five.png"],
            top_Link: [
                {img_name: 'faReact', link: 'React Native'},
                {img_name: 'sql', link: 'MSSQL'},
                {img_name: 'net', link: 'Asp.Net Core Web Api'}
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/Bitirme-Projesi-2'},
                {img_name: 'faFile', link: "animalous", pdf: true},
            ]
        },
        2: {
            title: "Makina Öğrenmesi ile Cümle Oluşturucu",
            text: "Makina öğrenmesi kullanılarak kelime verildiğinde anlamlı kelimeleri bir araya getirerek anlamlı cümleler oluşturması sağlanmıştır.",
            img_Url: ["three_one.png"],
            top_Link: [
                {img_name: 'faPython', link: 'Python(Transformers)'},
                {img_name: 'faPython', link: 'Python(PyQt5)'},
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/Proje_1'},
                {img_name: 'faFile', link: "sentence generator", pdf: true},
            ]
        },
        3: {
            title: "Online Eğitim Sistemi Otomasyonu",
            text: "Derslerin Zoom üzerinden otomatik tanımlanıp öğrencilerin girebileceği sistem.",
            img_Url: ["none.png"],
            top_Link: [
                {img_name: 'sql', link: 'MSSQL'},
                {img_name: 'net', link: 'Asp.Net Core MVC5'},
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/Proje_2'},
                {img_name: 'faFile', link: "online education system", pdf: true},
            ] 
        },
        4: {
            title: "Node.js - Kafka Bilgisayarlar Arası Haberleşme",
            text: "Kafka yardımı ile birden fazla kişi ağ üzerinden topice bağlanarak haberleşmiştir.",
            img_Url: ["none.png"],
            top_Link: [
                {img_name: 'sql', link: 'PostgreSql'},
                {img_name: 'faNode', link: 'Node.js'},
                {img_name: 'kafka', link: 'Kafka'},
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/kafka-nodejs-localnetwork'}
            ]
        },
        5: {
            title: "Node.js - React Market",
            text: "Dockerize edilmiş market sayfası",
            img_Url: ["five_one.png"],
            top_Link: [
                {img_name: 'sql', link: 'PostgreSql'},
                {img_name: 'faNode', link: 'Node.js'},
                {img_name: 'faReact', link: 'React'},
                {img_name: 'faDocker', link: 'Docker'},
            ],
            bottom_Link: [
                {img_name: 'faGithub', link: 'https://github.com/Halilozler/react-nodejs-postgresql-market-app'}
            ]
        },
}