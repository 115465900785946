import React, { useEffect } from 'react'
import { motion } from "framer-motion"
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faHome } from "@fortawesome/free-solid-svg-icons";
import PdfFile from "../pdf/cv.pdf"
//import { Link } from 'react-router-dom';

const Cv = ({setOpen}) => {
    useEffect(() => {
        setOpen(1);
    },[])
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
            <motion.div 
                style={{height:"100%", width:"100%"}}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0, transition: {duration: 0.1}}}
                >
                    
                {/* <div className="absolute top-1 left-1 z-10">
                    <Link to={"/"}>
                    <div className="border-[1px] border-stone-700 rounded cursor-pointer hover:bg-blue-500 hover:text-white">
                        <FontAwesomeIcon className=" w-7 h-7" icon={faHome} />
                    </div>
                    </Link>
                </div> */}
                <Viewer fileUrl={PdfFile} defaultScale={1}/> 
            </motion.div>
        </Worker>
        
    )
}

export default Cv