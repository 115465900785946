/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react'
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Proje from './Proje';
/*
import { getData } from './../service/service';
*/
import { connect } from 'react-redux';
import { setMain, setProje } from '../store/site';


class Projects  extends Component {
    constructor(props) {
        super(props);
        this.props.setOpen(1);
        this.state = {
            first_open: true,
            first_inter: true,
            right: 0,
            middle: 0,
            left: 1,
            old: 1,
            width: window.innerWidth,
            newTarget: 0,
            oldTarget: 0,
            responsive_slider: 0
        }
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    updateDimensions = () => {
        this.setState({width: window.innerWidth});
    };
    loop3;loop4;
    async componentDidMount(){
        //if(document.getElementsByClassName("project_box").length === 0)
        setTimeout(() => {
            this.setState({right: document.getElementsByClassName("project_box").length - 1});
        })
        window.addEventListener("resize", this.updateDimensions);
        /*
        if(this.props.data.length === 0){
            getData();
        }
        */
        if(this.props.data.length === 0){
            this.props.setProje();
        }
        //
        if(this.state.first_open == true){
            this.setState({first_open: false})
                if(this.props.data[this.state.middle]?.img_Url.length != 1){
                    this.loop3 = setInterval(this.loop2, 3000);
                }
        }
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.updateDimensions);
        clearInterval(this.loop2);
    }
    componentDidUpdate(prevProp){
        if(this.props.data[this.state.middle].img_Url.length != 1){
            if(this.props.main !== prevProp.main){
                if(this.state.first_inter === true){
                    this.loop4 = setInterval(this.loop2, 3000);
                    this.setState({first_inter: false});
                }else{
                    clearInterval(this.loop4);
                    this.loop4 = setInterval(this.loop2, 3000);
                }
                clearInterval(this.loop3);
            }
        }else{
            clearInterval(this.loop3);
        }
    }
    
    next = async() => {
        const box = document.getElementsByClassName("project_box").length - 1;
        const {right, middle, left} = this.state;
        this.zeroSlider(middle);
        await this.setState({old: left});
        if(middle === 0){
            await this.setState({middle: box});
        }else{
            await this.setState({middle: middle - 1});
        }
        if(left === 0){
            await this.setState({left: box});
        }else{
            await this.setState({left: left - 1});
        }
        if(right === 0){
            await this.setState({right: box});
        }else{
            await this.setState({right: right - 1});
        }
        //this.zeroSlider();
        this.getProjects(1);
    }

    back = async() => {
        const box = document.getElementsByClassName("project_box").length - 1;
        const {right, middle, left} = this.state;
        this.zeroSlider(middle);
        await this.setState({old: right});
        if(middle === box){
            await this.setState({middle: 0});
        }else{
            await this.setState({middle: middle + 1});
        }
        if(left === box){
            await this.setState({left: 0});
        }else{
            await this.setState({left: left + 1});
        }
        if(right === box){
            await this.setState({right: 0});
        }else{
            await this.setState({right: right + 1});
        }
        //this.zeroSlider();
        this.getProjects(0);
    }

    zeroSlider = (last) => {
        this.setState({newTarget: 0, oldTarget: 0}); 
        const last_img = document.getElementsByClassName(`img_slider-${last}`);
        last_img[0].style.opacity = 1;
        for (let i = 1; i < last_img.length; i++) {
            last_img[i].style.opacity = 0;
        }
    }

    clearBottom = () => {

    }

    disableButton(buttons){
        buttons[0].style.zIndex = 0;
        buttons[0].style.pointerEvents = "none";
        buttons[1].style.zIndex = 0;
        buttons[1].style.pointerEvents = "none";
    }

    enableButton(buttons){
        buttons[0].style.zIndex = 40;
        buttons[0].style.pointerEvents = "auto";
        buttons[1].style.zIndex = 40;
        buttons[1].style.pointerEvents = "auto";
    }

    /*
    setSlider(){
        const slider = document.getElementsByClassName("slider");
        const slider_m = document.getElementsByClassName("slider-main");
        const mainSlider = 'slider slider-main h-full w-96 relative';
        const otherSlider = 'slider slider-other h-full w-96 relative';
        if(slider_m.length !== 0){
            slider_m[0].className = otherSlider;
        }else{
            for (let i = 0; i < slider.length; i++) {
                slider[i].className = otherSlider;
            }
        }
        slider[this.state.middle].className = mainSlider;

        this.props.setMain(this.state.middle);
    }
    */

    getProjects(value) {
        const {right, middle, left,old} = this.state;
        const box = document.getElementsByClassName("project_box");
        const main_box = 'first project_box h-[70vh] w-[60vw] border-[1px] bg-stone-900 border-stone-700 rounded-extraLarge z-40 overflow-hidden flex';
        const left_box = 'second project_box h-[60vh] w-[60vw] border-[1px] border-stone-700 rounded-extraLarge absolute z-30 left-[calc(50%_-_550px)] overflow-hidden flex';
        const right_box = 'last project_box h-[60vh] w-[60vw] border-[1px] border-stone-700 rounded-extraLarge absolute right-[calc(50%_-_550px)] z-30 overflow-hidden flex'
        const back_box = 'other project_box h-[70vh] w-[60vw] absolute border-[1px] border-stone-700 rounded-extraLarge z-10 overflow-hidden'
        const buttons = document.getElementsByClassName("button_nextBack");
        this.disableButton(buttons);
        let i = 60;
        let j = 70;
        let a = 30;
        let f = 30;
        let b = 40;
        let c = 450;
        let d = 550;
        if(value === 1){
            box[left].style.position = "absolute";
            box[right].className = right_box;
            const next_loop = setInterval(() => {
                box[left].style.height = `${j}vh`
                box[left].style.zIndex = b
                box[left].style.left = `calc(50% - ${c}px)`
                box[old].style.zIndex = a
                box[middle].style.height = `${i}vh`
                box[middle].style.zIndex = f
                box[middle].style.right = `calc(50% - ${d}px)`
                i = i + 1;
                j = j - 1;
                b = b - 1;
                c = c + 10;
                a = a - 1;
                f = f + 1;
                d = d - 10.1;
                if(j <= 59){
                    clearInterval(next_loop);
                    box[left].className = left_box;
                    box[left].style = null
                    box[old].className = back_box;
                    box[old].style = null;
                    box[middle].className = main_box;
                    box[middle].style = null;
                    this.enableButton(buttons);
                }
            },50)
        }else{
            box[right].style.position = "absolute";
            box[left].className = left_box;
            const back_loop = setInterval(() => {
                box[right].style.height = `${j}vh`
                box[right].style.zIndex = b
                box[right].style.right = `calc(50% - ${c}px)`
                box[old].style.zIndex = a
                box[middle].style.height = `${i}vh`
                box[middle].style.zIndex = f
                box[middle].style.left = `calc(50% - ${d}px)`
                i = i + 1;
                j = j - 1;
                b = b - 1;
                a = a - 1;
                f = f + 1;
                c = c + 10.1;
                d = d - 10;
                if(j <= 59){
                    clearInterval(back_loop);
                    box[right].className = right_box;
                    box[right].style = null
                    box[old].className = back_box;
                    box[old].style = null;
                    box[middle].className = main_box;
                    box[middle].style = null;
                    this.enableButton(buttons);
                }
            },50)
        }
        // this.setSlider();
        this.props.setMain(this.state.middle);
    }

    loop2 = async() => {
        if(this.props.data[this.state.middle].img_Url.length != 1){
            if(this.state.width > 1100){
                if(this.state.newTarget === document.getElementsByClassName(`img_slider-${this.props.main}`).length - 1){
                    this.setState({oldTarget: this.state.newTarget, newTarget: 0}, () => {
                        this.getImage();
                        // this.auto_button();
                    })
                }else{
                    await this.setState({oldTarget: this.state.newTarget, newTarget: this.state.newTarget + 1}, () => {
                        this.getImage();
                        // this.auto_button();
                    })
                }
            }else{
                if(this.state.newTarget === document.getElementsByClassName(`img_slider-${this.state.responsive_slider}`).length - 1){
                    this.setState({oldTarget: this.state.newTarget, newTarget: 0}, () => {
                        if(this.state.responsive_slider === document.getElementsByClassName("slider").length - 1){
                            this.setState({responsive_slider: 0})
                        }else{
                            this.setState({responsive_slider: this.state.responsive_slider + 1})
                        }
                        this.getImage();
                        // this.auto_button();
                    })
                }else{
                    await this.setState({oldTarget: this.state.newTarget, newTarget: this.state.newTarget + 1}, () => {
                        this.getImage();
                        // this.auto_button();
                    })
                }
            }
        }
    }
    /*
    bottom_button = async(e) => {
        if(e.target.id !== "bottom_button_main"){
            const value = e.target.className.split(" ")[1];
            if(value !== "active"){
                const old_Target = document.getElementsByClassName("but active")[0];
                old_Target.className = "but passive ml-2 w-3 h-3 rounded-full";
                const target = document.getElementById(e.target.id);
                target.className = "but active ml-2 w-3 h-3 rounded-full";
                await this.setState({newTarget: parseInt(e.target.id), oldTarget: old_Target.id});
                this.getImage()
            }
        }
    }

    auto_button = async() => {
        const value = document.getElementById(this.state.newTarget);
        console.log(value);
        if(value.className.split(" ")[1] !== "but active"){
            const old_Target = document.getElementsByClassName("but active")[0];
            old_Target.className = "but passive ml-2 w-3 h-3 rounded-full";
            value.className = "but active ml-2 w-3 h-3 rounded-full";
        }
    }
    */

    getImage = () => {
        if(this.state.width > 1100){
            const new_img = document.getElementsByClassName(`img_slider-${this.props.main}`)[this.state.newTarget];
            const old_img = document.getElementsByClassName(`img_slider-${this.props.main}`)[this.state.oldTarget];
            
            let a = 1;
            let b = 0;
            if(new_img){
                const loop = setInterval(() => {
                    new_img.style.opacity = b;
                    old_img.style.opacity = a;
                    b = b + 0.1;
                    a = a - 0.1;
                    if(b >= 1){
                        clearInterval(loop);
                    }
                },30)
            }
        }else{
            if(this.state.newTarget != this.state.oldTarget){
                const new_img = document.getElementsByClassName(`img_slider-${this.state.responsive_slider}`)[this.state.newTarget];
                const old_img = document.getElementsByClassName(`img_slider-${this.state.responsive_slider}`)[this.state.oldTarget];

                let a = 1;
                let b = 0;
                if(new_img){
                    const loop = setInterval(() => {
                        new_img.style.opacity = b;
                        old_img.style.opacity = a;
                        b = b + 0.1;
                        a = a - 0.1;
                        if(b >= 1){
                            clearInterval(loop);
                        }
                    },30)
                }
            }
        }
    }

    render() { 
        return (
            <motion.div
            className="bg-stone-900 w-[100vw] h-[100vh] overflow-hidden txt"
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0, transition: {duration: 0.1}}}
        >
            {this.state.width < 1100 ?
                <div className=' bg-stone-900 h-[95%] w-full flex py-9 justify-center scrollbar-hide overflow-y-auto overflow-x-hidden'>
                    <div className='w-[95%] flex flex-wrap justify-center gap-9'>
                    {
                            Object.keys(this.props.data).map((item, i) => {
                                return (
                                    <Proje key={item} id={item} phone={true} last={this.props.last} item={this.props.data[item]}/>
                            )})
                    } 
                        
                    </div>
                </div> 
            :
                <div className=' h-[90vh] w-full flex justify-center items-end text-white'>
                    <div className=' h-[80vh] w-[85vw] rounded flex justify-center items-center transition-all'>
                        <div className='h-[70vh] w-[60vw] bg-stone-900 absolute  rounded-extraLarge z-20'></div>

                        {
                            Object.keys(this.props.data).map((item, i) => {
                                return (
                                    <Proje key={item} id={item} last={this.props.last} item={this.props.data[item]}/>
                            )})
                        } 

                        <div>
                            <div className='button_nextBack w-14 h-14 absolute top-[calc(50%_-_28px)] right-[21%] z-50 rounded-full border-2 border-cyan-900 text-white transition-all'>
                                <div className=' w-8 h-8 mx-auto my-2' >
                                    <a className=' cursor-pointer' onClick={this.back}>
                                        <FontAwesomeIcon className=' w-full h-full' icon={ faAnglesRight}/>
                                    </a>
                                </div>
                            </div>
                            <div className='button_nextBack w-14 h-14 absolute top-[calc(50%_-_28px)] left-[21%] z-50 rounded-full border-2 border-cyan-900 text-white transition-all'>
                                <div className=' w-8 h-8 mx-auto my-2 '>
                                    <a className=' cursor-pointer' onClick={this.next}>
                                        <FontAwesomeIcon className=' w-full h-full' icon={ faAnglesLeft}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className=' fixed right-[calc(50%_-_18px)] bottom-4 cursor-pointer z-10 '>
                <Link to={"/"}>
                    <a target="_blank" rel="noreferrer" className="button">
                        <div className="icon">
                            <FontAwesomeIcon className="icon_item" icon={faHome} />
                        </div>
                        <div className="btn_text right-[calc(50%_-_18px)]">
                            <p>Home</p>
                        </div>
                    </a>
                </Link>
            </div>
        </motion.div>
        );
    }
}


const mapStateToProps = function(state) {
    return{
        data: state.site.proje,
        last: state.site.last,
        main: state.site.main
    }
}
const mapDispatchToProps = { setMain, setProje }

export default connect(mapStateToProps, mapDispatchToProps)(Projects);


