import React from 'react'
import Slider from './Slider'
import Tools from './Tools'

const ProjectItem_responsive = ({item, id}) => {
    return (
        <>
            <div className=" bg-gray-900 h-full w-[calc(100%_-_100px)] text-white">
                <div className="relative h-16">
                    <div className="absolute top-4 left-4 h-[50px] flex justify-between">
                        {
                            item.top_Link.map(key => {
                                return(
                                    <Tools item={key} location={true}/>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="h-[calc(100%_-_65px)] flex">
                    <div className="h-full w-full flex flex-col">
                        <div className="w-full h-[calc(100%)] flex items-center justify-center text-3xl overflow-auto">
                            <p>{item.title}</p> 
                        </div>
                        <div className="w-full h-[40px] flex justify-center">
                            {
                                item.bottom_Link.map(key => {
                                    return(
                                        <Tools item={key} location={false}/>
                                    )
                                })
                            } 
                        </div>
                    </div>
                </div>
            </div>
            <div className='h-full w-[150px]'>
                <Slider id={id} img={item.img_Url}/>
            </div>
            
        </>
    )
}

export default ProjectItem_responsive