import { createSlice } from '@reduxjs/toolkit'
import { data } from "./data"

const initialState = {
    
    proje: [],
    last: 0,
    main: 0,
}
/*
const data = {0: {
    text: "text",
    title: "title",
    img_Url: ["10zuj64vmockaz5.png"],
    top_Link: [
        {img_name: 'sql', link: 'link'},
        {img_name: 'net', link: 'link'},
    ],
    bottom_Link: [
        {img_name: 'faGithub', link: 'link'},
        {img_name: 'site', link: 'link'},
    ]
},
1: {
    text: "text",
    title: "title",
    img_Url: ["10zuj64vmockaz5.png"],
    top_Link: [
        {img_name: 'faGithub', link: 'link'},
    ],
    bottom_Link: [
        {img_name: 'faGithub', link: 'github'},
    ]
}}
*/

export const site = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setProje: (state, action) => {
            //state.proje = action.payload;
            state.proje = data
            //console.log(state.proje);
            state.last = Object.keys(state.proje).length - 1;
        },
        setMain: (state, action) => {
            state.main = action.payload;
        }
    },
})

export const { setProje, setMain } = site.actions
export default site.reducer